import styled from 'styled-components';

export const ResultSectionContainer = styled.div`
  .result-section--wrapper {
    max-width: 1170px;
    margin: 0 auto;
    padding: 100px 30px;

    .result-section--title {
      font-size: 40px;
      font-weight: 900;
      line-height: 150%;
      text-align: center;
    }

    .result-section--description {
      font-size: 24px;
      text-align: center;
      max-width: 750px;
      margin: 40px auto 0;

      button {
        height: 60px;
        width: 200px;
        font-size: 18px;
      }
    }
  }

  @media (min-width: 768px) {
    .result-section--wrapper {
      max-width: 750px;
    }
  }

  @media (min-width: 992px) {
    .result-section--wrapper {
      max-width: 970px;
    }
  }

  @media (min-width: 1200px) {
    .result-section--wrapper {
      max-width: 1170px;
    }
  }
`;