import React, { useState, useEffect, Fragment } from 'react';
import * as queryString from "query-string";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/theme/app';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from 'containers/App/app.style';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'containers/App/Navbar';
import Footer from 'containers/App/Footer';
import ResultSection from 'containers/App/ResultSection';
import { DrawerProvider } from 'common/contexts/DrawerContext';

import CircularProgressOverlay from 'common/mycomponents/CircularProgressOverlay';
import '@redq/reuse-modal/es/index.css';

const ResultPage = ({ location }) => {
  const { type } = queryString.parse(location.search);

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <CircularProgressOverlay>
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar theme="white" />
              </DrawerProvider>
              <ResultSection type={type} />
            </Sticky>
            <Footer />
          </AppWrapper>
        </CircularProgressOverlay>
      </Fragment>
    </ThemeProvider>
  );
};

export default ResultPage;