import React from 'react';

import { MyCardRow } from 'common/mycomponents/MyCard';
import MyDescription from 'common/mycomponents/MyDescription';
import MyButton from 'common/mycomponents/MyButton';

import { ResultSectionContainer } from './style';

const ResultSection = ({ type }) => {
  return (
    <ResultSectionContainer>
      <div className="result-section--wrapper">
        <MyCardRow direction="column">
          {type === 'success' && (
            <MyDescription>
              <p className="result-section--title success-color">Pergo Portal is Created</p>
              <p className="result-section--description">Your Pergo Portal is Ready.<br/>Please click the link below to set your password and get rolling.</p>
              <p className="result-section--description">
                <a href={process.env.PORTAL_SITE_URL} target="_blank">
                  <MyButton color="primary">
                    Go To Portal
                  </MyButton>
                </a>
              </p>
            </MyDescription>
          )}
          {type === 'fail' && (
            <MyDescription>
              <p className="result-section--title error-color">Something went wrong.<br/>Sorry, please try again.</p>
              <p className="result-section--description">
                <a href='/login'>
                  <MyButton color="primary">
                    Restart
                  </MyButton>
                </a>
              </p>
            </MyDescription>
          )}
        </MyCardRow>
      </div>
    </ResultSectionContainer>
  )
}

export default ResultSection;
